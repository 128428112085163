import React from "react";
import {Button, Col, Container, NavItem, NavLink, Row} from "reactstrap";
import {Link} from "react-router-dom";

class Prices extends React.Component {

    render() {
        return (
            <>
                <section className="section section-lg bg-gradient-secondary pb-150">

                    <Container>
                        <h4 className="text-center display-3">Server Plans</h4>
                        <p className="text-center pb-3"><i>After the initial payment, we'll send you an email to your paypal address</i></p>

                        {/* First row */}

                        <Row className="row-grid justify-content-center">
                            <Col className="col-sm-6 col-lg-4 pb-4 mb-4">
                                <div className="card shadow-lg--hover shadow border-0">
                                    <div className="card-body py-4">
                                        <h5 className="text-success text-uppercase font-weight-bold mb-2">Silverfish</h5>
                                        <h5 className="text-primary font-weight-light"><b>€2</b> Monthly</h5>
                                        <p className="mb-0"><b>1GB DDR4 Memory</b></p>
                                        <p className="mb-0"><b>2 CPU Cores up to 5Ghz</b></p>
                                        <p className="mb-0"><b>5 GB NVMe SSD</b></p>
                                        <Button
                                            className="mt-4 float-right"
                                            color="success"
                                            onClick={() => {
                                                // eslint-disable-next-line no-undef
                                                window.fcWidget.open({ name: 'Sales', replyText: "Hello, I'd like to purchase..." })
                                            }}
                                        >
                                            Chat to order
                                        </Button>
                                    </div>
                                </div>
                            </Col>
                            <Col className="col-sm-6 col-lg-4 pb-4 mb-4">
                                <div className="card shadow-lg--hover shadow border-0">
                                    <div className="card-body py-4">
                                        <h5 className="text-success text-uppercase font-weight-bold mb-2">Pig</h5>
                                        <h5 className="text-primary font-weight-light"><b>€4</b> Monthly</h5>
                                        <p className="mb-0"><b>2GB DDR4 Memory</b></p>
                                        <p className="mb-0"><b>2 CPU Cores up to 5Ghz</b></p>
                                        <p className="mb-0"><b>10 GB NVMe SSD</b></p>
                                        <Button
                                            className="mt-4 float-right"
                                            color="success"
                                            onClick={() => {
                                                // eslint-disable-next-line no-undef
                                                window.fcWidget.open({ name: 'Sales', replyText: "Hello, I'd like to purchase..." })
                                            }}
                                        >
                                            Chat to order
                                        </Button>
                                    </div>
                                </div>
                            </Col>
                            <Col className="col-sm-6 col-lg-4 pb-4 mb-4">
                                <div className="card shadow-lg--hover shadow border-0">
                                    <div className="card-body py-4">
                                        <h5 className="text-success text-uppercase font-weight-bold mb-2">Zombie</h5>
                                        <h5 className="text-primary font-weight-light"><b>€6</b> Monthly</h5>
                                        <p className="mb-0"><b>3GB DDR4 Memory</b></p>
                                        <p className="mb-0"><b>3 CPU Cores up to 5Ghz</b></p>
                                        <p className="mb-0"><b>15 GB NVMe SSD</b></p>
                                        <Button
                                            className="mt-4 float-right"
                                            color="success"
                                            onClick={() => {
                                                // eslint-disable-next-line no-undef
                                                window.fcWidget.open({ name: 'Sales', replyText: "Hello, I'd like to purchase..." })
                                            }}
                                        >
                                            Chat to order
                                        </Button>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        {/* Second row */}

                        <Row className="row-grid justify-content-center">
                            <Col className="col-sm-6 col-lg-4 pb-4 mb-4">
                                <div className="card shadow-lg--hover shadow border-0">
                                    <div className="card-body py-4">
                                        <h5 className="text-success text-uppercase font-weight-bold mb-2">Giant</h5>
                                        <h5 className="text-primary font-weight-light"><b>€8</b> Monthly</h5>
                                        <p className="mb-0"><b>4GB DDR4 Memory</b></p>
                                        <p className="mb-0"><b>4 CPU Cores up to 5Ghz</b></p>
                                        <p className="mb-0"><b>20 GB NVMe SSD</b></p>
                                        <Button
                                            className="mt-4 float-right"
                                            color="success"
                                            onClick={() => {
                                                // eslint-disable-next-line no-undef
                                                window.fcWidget.open({ name: 'Sales', replyText: "Hello, I'd like to purchase..." })
                                            }}
                                        >
                                            Chat to order
                                        </Button>
                                    </div>
                                </div>
                            </Col>
                            <Col className="col-sm-6 col-lg-4 pb-4 mb-4">
                                <div className="card shadow-lg--hover shadow border-0">
                                    <div className="card-body py-4">
                                        <h5 className="text-success text-uppercase font-weight-bold mb-2">Free</h5>
                                        <h5 className="text-primary font-weight-light"><b>Absolutely</b> Free</h5>
                                        <p className="mb-0"><b>1GB DDR4 Memory</b></p>
                                        <p className="mb-0"><b>1 CPU Cores @ 3.35Ghz</b></p>
                                        <p className="mb-0"><b>5 GB SSD</b></p>
                                        <Button
                                            className="mt-4 float-right"
                                            color="success"
                                            onClick={() => window.open("https://discord.gg/firstblock")}
                                        >
                                            Deploy now
                                        </Button>
                                    </div>
                                </div>
                            </Col>
                            <Col className="col-sm-6 col-lg-4 pb-4 mb-4">
                                <div className="card shadow-lg--hover shadow border-0">
                                    <div className="card-body py-4">
                                        <h5 className="text-success text-uppercase font-weight-bold mb-2">Custom</h5>
                                        <h5 className="text-primary font-weight-light"><b>Unknown</b> Monthly</h5>
                                        <p className="mb-0"><b>Custom GB DDR4 Memory</b></p>
                                        <p className="mb-0"><b>Custom vCPU amount</b></p>
                                        <p className="mb-0"><b>Custom GB NVMe SSD</b></p>
                                        <Button
                                            className="mt-4 float-right"
                                            color="success"
                                            onClick={() => {
                                                // eslint-disable-next-line no-undef
                                                window.fcWidget.open({ name: 'Sales', replyText: "Hello, I'd like to purchase..." })
                                            }}
                                        >
                                            Contact us
                                        </Button>
                                    </div>
                                </div>
                            </Col>
                        </Row>


                    </Container>

                    {/* SVG separator */}
                    <div className="separator separator-bottom separator-skew">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            preserveAspectRatio="none"
                            version="1.1"
                            viewBox="0 0 2560 100"
                            x="0"
                            y="0"
                        >
                            <polygon
                                className="fill-white"
                                points="0 0 2560 100 0 100"
                            />
                        </svg>
                    </div>
                </section>
            </>
        )
    }
}

export default Prices