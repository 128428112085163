/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link } from "react-router-dom";
// JavaScript plugin that hides or shows a component based on your scroll
import Headroom from "headroom.js";
// reactstrap components
import {
    Button,
    UncontrolledCollapse,
    DropdownMenu,
    DropdownItem,
    DropdownToggle,
    UncontrolledDropdown,
    Media,
    NavbarBrand,
    NavItem,
    NavLink,
    Nav,
    Container,
    Row,
    Col,
    UncontrolledTooltip
} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDiscord, faGitlab} from "@fortawesome/free-brands-svg-icons";

class Content extends React.Component {
    componentDidMount() {
        let headroom = new Headroom(document.getElementById("navbar-main"));
        // initialise
        headroom.init();
    }

    state = {
        collapseClasses: "",
        collapseOpen: false
    };

    onExiting = () => {
        this.setState({
            collapseClasses: "collapsing-out"
        });
    };

    onExited = () => {
        this.setState({
            collapseClasses: ""
        });
    };

    render() {
        return (
            <>
                <Container>
                    <NavbarBrand className="mr-lg-5" to="/" tag={Link}>
                        <img
                            style={{ height: 50 }}
                            alt="DeploysFlow"
                            src={
                                // eslint-disable-next-line no-restricted-globals
                                window.location.href.includes('deploysflow') ?
                                    require("assets/img/brand/dflogo.png")
                                    :
                                    require("assets/img/brand/logo.png")
                            }
                        />
                    </NavbarBrand>
                    <button className="navbar-toggler" id="navbar_global">
                        <span className="navbar-toggler-icon" />
                    </button>
                    <UncontrolledCollapse
                        toggler="#navbar_global"
                        navbar
                        className={this.state.collapseClasses}
                        onExiting={this.onExiting}
                        onExited={this.onExited}
                    >
                        <div className="navbar-collapse-header">
                            <Row>
                                <Col className="collapse-brand" xs="6">
                                    <Link to="/">
                                        <img
                                            alt={'DeploysFlow'}
                                            src={
                                                window.location.href.includes('deploysflow') ?
                                                    require("assets/img/brand/dflogo.png")
                                                    :
                                                    require("assets/img/brand/logo.png")
                                            }
                                        />
                                    </Link>
                                </Col>
                                <Col className="collapse-close" xs="6">
                                    <button className="navbar-toggler" id="navbar_global">
                                        <span />
                                        <span />
                                    </button>
                                </Col>
                            </Row>
                        </div>
                        <Nav className="navbar-nav-hover align-items-lg-center" navbar>
                            <UncontrolledDropdown nav>
                                <DropdownToggle nav>
                                    <i className="ni ni-ui-04 d-lg-none mr-1" />
                                    <span className="nav-link-inner--text">Services</span>
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-xl">
                                    <div className="dropdown-menu-inner">
                                        <Media
                                            className="d-flex align-items-center"
                                            to="/minecraft"
                                            tag={Link}
                                        >
                                            <div className="icon icon-shape bg-gradient-primary rounded-circle text-white">
                                                <i className="fa fa-cube" />
                                            </div>
                                            <Media body className="ml-3">
                                                <h6 className="heading text-primary mb-md-1">
                                                    Minecraft Hosting
                                                </h6>
                                                <p className="description d-none d-md-inline-block mb-0">
                                                    High-performance, Ryzen 7 & Epyc powered Minecraft servers with an incredible Firewall Bot Protection.
                                                </p>
                                            </Media>
                                        </Media>
                                        <Media
                                            className="d-flex align-items-center"
                                            href="https://discord.deploysflow.cloud"
                                            target="_blank"
                                        >
                                            <div className="icon icon-shape bg-gradient-success rounded-circle text-white">
                                                <i className="fa fa-server" />
                                            </div>
                                            <Media body className="ml-3">
                                                <h6 className="heading text-success mb-md-1">
                                                    VPS Servers
                                                </h6>
                                                <p className="description d-none d-md-inline-block mb-0">
                                                    Incredibly fast cloud servers designed to unleash full potential of your cloud applications and game servers.
                                                </p>
                                            </Media>
                                        </Media>
                                        <Media
                                            className="d-flex align-items-center"
                                        >
                                            <div className="icon icon-shape bg-gradient-info rounded-circle text-white">
                                                <i className="fa fa-car" />
                                            </div>
                                            <Media body className="ml-3">
                                                <h5 className="heading text-primary mb-md-1">
                                                    SAMP Hosting
                                                </h5>
                                                <p className="description d-none d-md-inline-block mb-0">
                                                    Coming Soon.
                                                </p>
                                            </Media>
                                        </Media>
                                        <Media
                                            className="d-flex align-items-center"
                                            href="https://discord.deploysflow.cloud"
                                            target="_blank"
                                        >
                                            <div className="icon icon-shape bg-gradient-warning rounded-circle text-white">
                                                <i className="ni ni-palette" />
                                            </div>
                                            <Media body className="ml-3">
                                                <h5 className="heading text-warning mb-md-1">
                                                    Plugin Development
                                                </h5>
                                                <p className="description d-none d-md-inline-block mb-0">
                                                    Want us to make a custom Minecraft plugin for you? Contact us on Discord!
                                                </p>
                                            </Media>
                                        </Media>
                                    </div>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                            <UncontrolledDropdown nav>
                                <DropdownToggle nav>
                                    <i className="ni ni-collection d-lg-none mr-1" />
                                    <span className="nav-link-inner--text">Pages</span>
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem href="https://panel.thearcadia.xyz">
                                        GamePanel
                                    </DropdownItem>
                                    <DropdownItem to="/armor" tag={Link}>
                                        FrontDoor
                                    </DropdownItem>
                                    <DropdownItem to="/tos" tag={Link}>
                                        Terms of Service
                                    </DropdownItem>
                                    <DropdownItem href="https://discord.deploysflow.cloud">
                                        Discord Community
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </Nav>
                        <Nav className="align-items-lg-center ml-lg-auto" navbar>
                            <NavItem>
                                <NavLink
                                    className="nav-link-icon"
                                    href="https://www.facebook.com/thearcadia.xyz"
                                    id="tooltip333589074"
                                    target="_blank"
                                >
                                    <i className="fa fa-facebook-square" />
                                    <span className="nav-link-inner--text d-lg-none ml-2">
                                       Facebook
                                    </span>
                                </NavLink>
                                <UncontrolledTooltip delay={0} target="tooltip333589074">
                                    Like us on Facebook
                                </UncontrolledTooltip>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className="nav-link-icon"
                                    href="https://discord.deploysflow.cloud"
                                    id="tooltip356693867"
                                    target="_blank"
                                >
                                    <FontAwesomeIcon icon={faDiscord}/>
                                    <span className="nav-link-inner--text d-lg-none ml-2">
                        Join us on Discord
                      </span>
                                </NavLink>
                                <UncontrolledTooltip delay={0} target="tooltip356693867">
                                    Join us on Discord
                                </UncontrolledTooltip>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className="nav-link-icon"
                                    href="https://twitter.com/ArcadiaService"
                                    id="tooltip184698705"
                                    target="_blank"
                                >
                                    <i className="fa fa-twitter-square" />
                                    <span className="nav-link-inner--text d-lg-none ml-2">
                                        Twitter
                                    </span>
                                </NavLink>
                                <UncontrolledTooltip delay={0} target="tooltip184698705">
                                    Follow us on Twitter
                                </UncontrolledTooltip>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className="nav-link-icon"
                                    href="https://gitlab.com/OpenSource02"
                                    id="tooltip112445449"
                                    target="_blank"
                                >
                                    <FontAwesomeIcon icon={faGitlab}/>
                                    <span className="nav-link-inner--text d-lg-none ml-2">
                                    Github
                                  </span>
                                </NavLink>
                                <UncontrolledTooltip delay={0} target="tooltip112445449">
                                    Star us on GitLab
                                </UncontrolledTooltip>
                            </NavItem>
                            <NavItem className="d-none d-lg-block ml-lg-4">
                                <Button
                                    className="btn-neutral btn-icon"
                                    color="default"
                                    href="https://dash.deploysflow.cloud"
                                    target="_blank"
                                >
                                  <span className="btn-inner--icon">
                                    <i className="fa fa-dashcube mr-2" />
                                  </span>
                                  <span className="nav-link-inner--text ml-1">
                                    Dashboard
                                  </span>
                                </Button>
                            </NavItem>
                        </Nav>
                    </UncontrolledCollapse>
                </Container>
            </>
        );
    }
}

export default Content;
