import React from "react";
import SimpleFooter from "../../../components/Footers/SimpleFooter";
import NavBarUntransparent from "../../../components/Navbars/NavBarUntransparent";
import {Container} from "reactstrap";
import LoadingBar from "react-top-loading-bar";
import ArmorPng from './ArmorSections/Armor.png'

class Armor extends React.Component {

    componentDidMount() {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        this.complete()
    }

    state = {
        loadingBarProgress: 10
    };

    complete = () => {
        this.setState({loadingBarProgress: 100});
    };

    onLoaderFinished = () => {
        this.setState({loadingBarProgress: 0});
    };

    render() {
        return (
            <>
                <NavBarUntransparent/>
                <LoadingBar
                    progress={this.state.loadingBarProgress}
                    height={3}
                    color="blue"
                    onLoaderFinished={() => this.onLoaderFinished()}
                />

                <section className="section bg-white">
                    <Container>
                        <h1 className="display-2 mt-5">FrontDoor&trade;</h1>
                        <hr/>
                        <h1>Introduction</h1>
                        <p>FrontDoor is our sophisticated system used to defend our Customers for large-scale network attacks & Game exploits including, but not limited to: Minecraft bots, Netty Exploits, SAMP crashers...</p>
                        <p>Our protection is built on an advanced, multi-layer infrastructure build to defend against any possible DDoS attacks at less than 0.1ms latency increase. A large Bot attack, for example, is already limited at Pre-Firewall, while further being reduced at the BlackHole firewall.</p>
                        <h1>How does it work</h1>
                        <p>FrontDoor is a system build on three layers:</p>
                        <ol>
                            <li>FrontDoor&trade; (D)DoS Filter: <p>The first thing the incoming traffic reaches is our Network Firewall powered by IONOS (1&1), Path.net or Anexia. All of them are leaders in DDoS Protection, and that's why we choose them to deliver the absolute best protection to our customers. The incoming DDoS attacks are filtered, no matter the strength or duration. In any case, you will never even know that your server is under a DDoS attack.</p></li>
                            <li>Firewall: <p>At this point we apply connection rate limiting and are further blocking suspicious connections that possibly pass through FrontDoor making sure malicious traffic never reaches your server.</p></li>
                            <li>
                                BlackHole&trade; : <p>As a Game Hosting provider, we do understand how important protection from game-specific exploits, attacks or "crashers" can be for your community, and that's why we've worked hard on implementing something truly special - BlackHole Firewall. It's main purpose is to defend your server from proxy-based attacks - such as NullPing, Minecraft Bot Attacks, BungeeCord Proxy attacks and similar. Although it is designed for Minecraft in mind, it does provide a great protection for other games as well.</p>
                            </li>
                        </ol>
                        <h1>Technical Details:</h1>
                        <ul>
                            <li><p>All Layer 3 / 4 / 7 Floods</p></li>
                            <li><p>UDP, TCP, SYN Filtering</p></li>
                            <li><p>Protection on all Ports</p></li>
                            <li><p>Unlimited Attacks per Month</p></li>
                            <li><p>Less than 0.1ms latency increase</p></li>
                            <li><p>More than 12 different PoP's</p></li>
                        </ul>
                        <h1>Is the protection guaranteed?</h1>
                        <p>Yes! We do guarantee your server will not ever fall from a DDoS Attack, but we can not guarantee our BlackHole firewall will be enough to defend your server from any Game-Specific attack (such as Minecraft bot attack or Netty attack). In those cases, we strongly suggest you to review the available AntiBot options and do strongly suggest you checking out our own one - <a href={"https://www.mc-market.org/resources/13386/"}>InterceptX</a>. Additionally, if we fail to protect your server from DDoS attacks, in the first seven days of service we offer a full refund!</p>
                        <img src={ArmorPng} alt={"Image could not be loaded"} width="100%"/>
                    </Container>
                </section>
                <SimpleFooter/>
            </>
        );
    }
}

export default Armor;