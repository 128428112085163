import React from "react";
import {Button, Card, CardBody, Col, Container, Row} from "reactstrap";

// reactstrap components

class Hero extends React.Component {
    render() {
        return (
            <>
                <section className="section section-lg section-shaped pb-80">
                    <div className="shape shape-style-3 shape-default">
                        <span />
                        <span />
                        <span />
                        <span />
                        <span />
                        <span />
                        <span />
                        <span />
                        <span />
                    </div>
                    <Container className="py-lg-md d-flex mt-5">
                        <div className="col px-0">
                            <Row className="align-items-center justify-content-center text-center">
                                <Col lg="6">
                                    <h1 className="display-1 text-white">
                                        {window.location.href.includes('deploysflow') ?
                                            'DeploysFlow'
                                            :
                                            'Arcadia Services'
                                        }
                                    </h1>
                                    <h2 className="display-4 font-weight-normal text-white">It's time to switch!</h2>
                                    <div className="btn-wrapper ">
                                        <Button
                                            className="bg-gradient-warning btn-icon mb-3 mt-3 mb-sm-0 ml-1 text-white"
                                            color="orange"
                                            onClick={() => window.scrollTo(0, 1700)}
                                        >
                                          <span className="btn-inner--icon mr-1">
                                            <i className="ni ni-button-play" />
                                          </span>
                                          <span className="btn-inner--text">
                                            Find out why
                                          </span>
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                    <Container className="mt-6">
                        <Row className="justify-content-center">
                            <Col lg="12">
                                <Row className="row-grid">
                                    <Col lg="6">
                                        <Card className="card-lift--hover shadow border-0">
                                            <CardBody>
                                                <div className="icon icon-shape icon-shape-primary bg-gradient-primary text-white rounded-circle mb-4">
                                                    <i className="ni ni-settings-gear-65" />
                                                </div>
                                                <h6 className="text-primary text-uppercase">
                                                    A Hosting Provider - DeploysFlow.cloud
                                                </h6>
                                                <p className="description mt-3">
                                                    DeploysFlow is a modern hosting provider - offering high performance, Epyc & R7 powered VPSes & Game Servers.
                                                </p>
                                                <Button
                                                    className="mt-4"
                                                    color="primary"
                                                    onClick={() => window.scrollTo(0, 1650)}
                                                >
                                                    Learn more
                                                </Button>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col lg="6">
                                        <Card className="card-lift--hover shadow border-0">
                                            <CardBody>
                                                <div className="icon icon-shape icon-shape-primary bg-gradient-warning text-white rounded-circle mb-4">
                                                    <i className="ni ni-palette" />
                                                </div>
                                                <h6 className="text-warning text-uppercase">
                                                    A Development Group - thearcadia.org
                                                </h6>
                                                <p className="description mt-3">
                                                    Are you in need of a custom high quality Minecraft plugins or Websites? Well, than you're on the right place!
                                                </p>
                                                <Button
                                                    className="mt-4"
                                                    color="warning"
                                                    href="https://discord.deploysflow.cloud"
                                                    onClick={e => e.preventDefault()}
                                                >
                                                    Contact us
                                                </Button>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>

                    {/* SVG separator */}
                    <div className="separator separator-bottom separator-skew">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            preserveAspectRatio="none"
                            version="1.1"
                            viewBox="0 0 2560 100"
                            x="0"
                            y="0"
                        >
                            <polygon
                                className="fill-white"
                                points="2560 0 2560 100 0 100"
                            />
                        </svg>
                    </div>
                </section>
            </>
        )
    }
}

export default Hero;