import React from "react";
import {Col, Container, Row} from "reactstrap";

// reactstrap components

class ForWhat extends React.Component {
    render() {
        return (
            <>
                <section className="section section-lg">
                    <Container>
                        <Row className="row-grid align-items-center">
                            <Col className="order-md-2" md="6">
                                <img
                                    alt="..."
                                    className="img-fluid floating"
                                    src={require("assets/img/theme/server.png")}
                                />
                            </Col>
                            <Col className="order-md-1" md="6">
                                <h5>For Large Networks</h5>
                                <p>Having a large game network requires a lot of time and energy, and the last thing you would want at that stage would be to waste your hard earned money. Unfortunately that is, most likely, what you are doing right now!
                                    Slow CPU and occasional lags should never be what you settle for. We care about our customers and our top priority is flawless user experience for minimal amounts of money.
                                    With Arcadia Services, you won't ever have to complain about annoyances,  and the prices are so low that you won't even notice paying them. Now is the time to stop supporting the money hungry, soulless companies that you were wasting your money on for so long. Go ahead, make the logical choice and we guarantee you that you won't regret it.</p>
                                <br/><h5>For Developers</h5>
                                <p>You've purchased some of our amazing plugins, but you'd like to get the SourceCode? Great, because the SourceCode of all of our free plugins is already published, and the SourceCode of our premium plugins can be purchased at an affordable price as well.</p>
                                <br/><h5>For Beginners</h5>
                                <p>All experts were once beginners, just like you are now. What brought us here was our passion and determination to learn and grow, and we are more than happy to help you do the same for a very small price. All of your problems can be resolved in just a few minutes thanks to our wonderful support team. They are there to answer any questions you might have and encourage you to learn on your own as well. So, make the first step towards your bright future and hop onto the Arcadia bandwagon.</p>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </>
        )
    }
}

export default ForWhat