import React from "react";
import {Button, Col, Container, Row} from "reactstrap";

// reactstrap components

class Panel extends React.Component {
    render() {
        return (
            <>
                <section className="section section-lg section-shaped">
                    <div className="shape shape-style-2 shape-default">
                        <span/>
                        <span/>
                        <span/>
                        <span/>
                        <span/>
                        <span/>
                        <span/>
                        <span/>
                        <span/>
                    </div>
                    <Container className="py-md">
                        <Row className="row-grid align-items-center justify-content-between">
                            <Col className="col-lg-6">
                                <h3 className="display-3 text-white">A beautiful Game Panel
                                    <span className="text-white">easy-to-use and lightning-fast</span>
                                </h3>
                                <p className="lead text-white">At Arcadia, we believe that a fundamental part of the hosting experience is Panel. We believe that with great, easy-to-use and intuitive control panel, our users are able to achieve more in less time. That's why we use Pterodactyl as our game server panel.</p>
                                <Button
                                    className="btn-neutral btn-icon"
                                    color="default"
                                    href="https://panel.thearcadia.xyz"
                                    target="_blank"
                                >
                                    <span className="btn-inner--icon">
                                        <i className="fa fa-dashcube mr-2" />
                                    </span>
                                    <span className="nav-link-inner--text ml-1">
                                        Visit Dashboard
                                    </span>
                                </Button>
                            </Col>
                            <Col className="col-lg-5 mb-lg-auto floating" md="6">
                                <div className="transform-perspective-right shadow rounded">
                                    <img
                                        className={'rounded'}
                                        alt="..."
                                        src={require("assets/img/theme/panel.png")}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    {/* SVG separator */}
                    <div className="separator separator-bottom separator-skew zindex-100">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            preserveAspectRatio="none"
                            version="1.1"
                            viewBox="0 0 2560 100"
                            x="0"
                            y="0"
                        >
                            <polygon
                                className="fill-white"
                                points="2560 0 2560 100 0 100"
                            />
                        </svg>
                    </div>
                </section>
            </>
        )
    }
}

export default Panel