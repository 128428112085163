/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// core components
import NavBar from "components/Navbars/NavBar.js";
import LoadingBar from "react-top-loading-bar";

// index page sections
import SimpleFooter from "../components/Footers/SimpleFooter";
import Hero from "./IndexSections/Hero";
import ForWhat from "./IndexSections/ForWhat";
import Nodes from "./IndexSections/Nodes";
import Panel from "./IndexSections/Panel";
import Features from "./IndexSections/Features";
import Project from "./IndexSections/Project";
import Discord from "./IndexSections/Discord";

class Index extends React.Component {

  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
    this.complete()
  }

  state = {
    loadingBarProgress: 10
  };

  complete = () => {
    this.setState({ loadingBarProgress: 100 });
  };

  onLoaderFinished = () => {
    this.setState({ loadingBarProgress: 0 });
  };

  render() {
    return (
        <>
          <LoadingBar
              progress={this.state.loadingBarProgress}
              height={3}
              color="blue"
              onLoaderFinished={() => this.onLoaderFinished()}
          />
          <NavBar />
          <main ref="main">
            <Hero/>
            <ForWhat/>
            <Nodes forMinecraft={false}/>
            <Panel/>
            <Features/>
            <Project/>
            <Discord/>
          </main>
          <SimpleFooter />
        </>
    );
  }
}

export default Index;
