import React from "react";
import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Row
} from "reactstrap";
import classnames from "classnames";

// reactstrap components

class Project extends React.Component {

    state = {
        loadingBarProgress: 10
    };

    render() {
        return (
            <>
                <section className="section section-lg bg-gradient-default">
                    <Container className="pt-lg pb-300">
                        <Row className="text-center justify-content-center">
                            <Col lg="10">
                                <h2 className="display-3 text-white">We'll help you release your project!</h2>
                                <p className="lead text-white">
                                    Whatever you need - Game Server, Dedicated Server, a website, or maybe a Minecraft plugin, our highly experienced team always got you covered in no time!
                                </p>
                            </Col>
                        </Row>
                        <Row className="row-grid mt-5">
                            <Col lg="4">
                                <div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
                                    <i className="fa fa-server" />
                                </div>
                                <h5 className="text-white mt-3">Server Hosting</h5>
                                <p className="text-white mt-3">
                                    We provide an enterprise Game & Dedicated server hosting
                                    able to suit everyone's needs.
                                </p>
                            </Col>
                            <Col lg="4">
                                <div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
                                    <i className="ni ni-ruler-pencil text-primary" />
                                </div>
                                <h5 className="text-white mt-3">Development Group</h5>
                                <p className="text-white mt-3">
                                    Need anything from a simple Minecraft plugin to a huge, glorious
                                    website? We'll get you covered in no time!
                                </p>
                            </Col>
                            <Col lg="4">
                                <div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
                                    <i className="ni ni-atom text-primary" />
                                </div>
                                <h5 className="text-white mt-3">Support</h5>
                                <p className="text-white mt-3">
                                    No matter what you purchase, you'll always get a fast support
                                    that will answer any of your questions and provide solutions.
                                </p>
                            </Col>
                        </Row>
                    </Container>
                    {/* SVG separator */}
                    <div className="separator separator-bottom separator-skew zindex-100">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            preserveAspectRatio="none"
                            version="1.1"
                            viewBox="0 0 2560 100"
                            x="0"
                            y="0"
                        >
                            <polygon
                                className="fill-white"
                                points="2560 0 2560 100 0 100"
                            />
                        </svg>
                    </div>
                </section>
                <section className="section section-lg pt-lg-0 section-contact-us">
                    <Container>
                        <Row className="justify-content-center mt--300">
                            <Col lg="8">
                                <Card className="bg-gradient-secondary shadow floating">
                                    <CardBody className="p-lg-5">
                                        <h4 className="mb-1">Want to work with us?</h4>
                                        <p className="mt-0">
                                            Your project is very important to us.
                                        </p>
                                        <FormGroup
                                            className={classnames("mt-5", {
                                                focused: this.state.nameFocused
                                            })}
                                        >
                                            <InputGroup className="input-group-alternative">
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="ni ni-user-run" />
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input
                                                    placeholder="Your name"
                                                    type="text"
                                                    disabled
                                                    onFocus={e => this.setState({ nameFocused: true })}
                                                    onBlur={e => this.setState({ nameFocused: false })}
                                                />
                                            </InputGroup>
                                        </FormGroup>
                                        <FormGroup
                                            className={classnames({
                                                focused: this.state.emailFocused
                                            })}
                                        >
                                            <InputGroup className="input-group-alternative">
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="ni ni-email-83" />
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input
                                                    placeholder="Email address"
                                                    type="email"
                                                    disabled
                                                    onFocus={e => this.setState({ emailFocused: true })}
                                                    onBlur={e => this.setState({ emailFocused: false })}
                                                />
                                            </InputGroup>
                                        </FormGroup>
                                        <FormGroup className="mb-4">
                                            <Input
                                                className="form-control-alternative"
                                                cols="80"
                                                name="name"
                                                placeholder="Type a message..."
                                                rows="4"
                                                disabled
                                                type="textarea"
                                            />
                                        </FormGroup>
                                        <div>
                                            <Button
                                                block
                                                className="btn-round"
                                                color="default"
                                                size="lg"
                                                href="https://discord.deploysflow.cloud"
                                            >
                                                Form under Maintenance, contact us on Discord
                                            </Button>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </>
        )
    }
}

export default Project