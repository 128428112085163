/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {Container} from "reactstrap";

// core components
import LoadingBar from "react-top-loading-bar";

// index page sections
import SimpleFooter from "../components/Footers/SimpleFooter";
import NavBarUntransparent from "../components/Navbars/NavBarUntransparent";

class tos extends React.Component {

    componentDidMount() {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        this.complete()
    }

    state = {
        loadingBarProgress: 10
    };

    complete = () => {
        this.setState({ loadingBarProgress: 100 });
    };

    onLoaderFinished = () => {
        this.setState({ loadingBarProgress: 0 });
    };

    render() {
        return (
            <>
                <NavBarUntransparent/>
                <LoadingBar
                    progress={this.state.loadingBarProgress}
                    height={3}
                    color="blue"
                    onLoaderFinished={() => this.onLoaderFinished()}
                />

                <section className="section bg-secondary">
                    <Container>
                        <h1 className="display-2 mt-5">Terms of Service</h1>
                        <hr/>
                        <ol>
                            <li>Introduction
                                <ol>
                                    <li>This document (the "Agreement", the "Terms") gives you information about Arcadia Services (referred to within this Agreement as "Arcadia", "us", "we", "our") and sets out the legal terms and conditions on which we may provide services (referred to within this Agreement as "Service" or collectively as "Services") to our clients (referred to within this Agreement as "you", "your") through our website.</li>
                                    <li>By using or accessing our Services, you are bound by these Terms. If you do not agree to abide by this Agreement, you are not authorized to use our Services.</li>
                                    <li>We reserve the right to amend the Terms at any time without notice. It is your responsibility to periodically check for any changes to the Terms.</li>
                                </ol>
                            </li>
                        </ol>
                        <ol start="2">
                            <li>Account Eligibility
                                <ol>
                                    <li>Arcadia Services are not available to persons under the age of 13. If you are between the ages of 13 and 18 (or between 13 and the age of legal majority in your jurisdiction of residence), you may only use our Services under the supervision of a parent or legal guardian who agrees to be bound by these Terms on your behalf.</li>
                                    <li>Upon registration and at all other times, it is your responsibility to provide true, current and accurate information which is completed to the best of your knowledge. Providing fraudulent information may result in the immediate termination of your Services and/or account.</li>
                                    <li>Upon purchase of our Services, in some cases, you may be required to prove your identity by providing us with government-issued identification and possibly a scan of the credit card used to make the purchase. Failure to provide this information upon request will result in the immediate cancellation of your order and may also result in your account being terminated.</li>
                                </ol>
                            </li>
                        </ol>
                        <ol start="3">
                            <li>Payments
                                <ol>
                                    <li>Arcadia shall not be liable whatsoever for any extra fees that may arise during the payment for your Services.</li>
                                    <li>We reserve the right to change our prices at any time. It is your responsibility to periodically review billing information provided by us via our website or other communication methods, such as email.</li>
                                    <li>Failure to provide payment for your Services entitles us to request the owed amount from external debt collectors or financial institutions.</li>
                                </ol>
                            </li>
                        </ol>
                        <ol start="4">
                            <li>Fraud
                                <ol>
                                    <li>Fraudulently using credit cards, electronic cheques, or any other payment method is a violation of this Agreement. We may report such actions to law enforcement and other organisations such as credit card companies and/or financial institutions.</li>
                                    <li>If we suspect any information provided by you is incorrect and/or fraudulent, we are entitled to suspend access to your Services or irreversibly terminate your Services at any time without notice.</li>
                                </ol>
                            </li>
                        </ol>
                        <ol start="5">
                            <li>Abuse
                                <ol>
                                    <li>Using your Services for illegal activity or against its intended use is strictly prohibited. Services that we suspect are being misused can be suspended or irreversibly terminated at any time without notice.</li>
                                    <li>Abuse is defined as continuous high resource use, attempting to access external systems without written permission, creating botnets, running and/or providing illegal content/software, hosting copyrighted material without permission, hosting racist content, spamming/raiding other services such as email or websites and in the case of Minecraft Services using and/or hosting non-Minecraft related files. Using any of your Services for these purposes will result in the immediate irreversible termination of the Service.</li>
                                    <li>Services hosted by Arcadia or on our systems are strictly prohibited from using illegal, leaked, or `nulled` content including but not limited to Minecraft plugins, websites and software and may be terminated upon abuse report.</li>
                                    <li>If you would like to report abuse or suspicious activity from a client, you may contact our abuse department via email at abuse@thearcadia.xyz or our online ticket system.</li>
                                </ol>
                            </li>
                        </ol>
                        <ol start="6">
                            <li>Support
                                <ol>
                                    <li>If you maliciously attack, spam or raid our support systems, we reserve the right to permanently ban you or refuse you access to such systems in the future resulting in you not receiving support for your Services.</li>
                                    <li>Arcadia reserves the right to deny support for outdated Services, Services that use old and/or outdated software This may be due to security concerns or other reasons which you will be notified of.</li>
                                </ol>
                            </li>
                        </ol>
                        <ol start="7">
                            <li>Refunds
                                <ol>
                                    <li>If the Service is your first with us, we offer a period of up to 24 hours in which you may request a refund for your Service by creating a support ticket.</li>
                                    <li>If your server is under DDoS Attack, and we're not able to protect you (even after you contact us), you will get a refund (this does not apply to 'Minecraft Bots', 'Null Pings' and similar)</li>
                                    <li>Refunds are not available through all payment gateways. It is important to note that we will only be able to provide refunds under PayPal & Skrill.</li>
                                    <li>Cancelling your Service via our website will make that Service ineligible for a refund in future.</li>
                                </ol>
                            </li>
                        </ol>
                        <ol start="8">
                            <li>Termination
                                <ol>
                                    <li>We may terminate your access to our Services, in whole or in part, without any notice in the event you: (i) fail to pay any due fees; (ii) violate these Terms; or (iii) cause harm to Arcadia, other clients or cause Arcadia or others to incur liability as determined by Arcadia Services at our sole discretion.</li>
                                    <li>Upon termination of your access to our Services, Arcadia Services will not refund any fees and/or charges accrued before the termination and you are liable for any unpaid fees.</li>
                                    <li>Arcadia may charge you for the remainder of the billing period in which the termination occurred.&nbsp;</li>
                                    <li>Upon termination of your Services for any reason, any data associated with the Service will be permanently deleted.</li>
                                </ol>
                            </li>
                        </ol>
                        <ol start="9">
                            <li>Disputes
                                <ol>
                                    <li>Any dispute or chargeback of payment to Arcadia will result in the immediate termination of all Services associated with your account. In some cases, this may be reversed under some circumstances such as the dispute being resolved.</li>
                                </ol>
                            </li>
                        </ol>
                        <ol start="10">
                            <li>Data
                                <ol>
                                    <li>You may be able to upload, store, publish, display and distribute information and/or content including but not limited to, text, photos and videos through your Services. You are liable for any and all content and activities conducted on or through your Services.</li>
                                    <li>By posting or distributing content through your Services, you consent to Arcadia that (i) you have all the necessary rights to post or distribute such content; and (ii) posting or distribution of such content does not infringe or violate the rights of any other third party. Arcadia reserves the right to intervene and possibly terminate your Services in cases where you infringe or violate the rights of a third party.</li>
                                    <li>Arcadia will not be liable whatsoever for any content hosted by our clients on our systems or any actions we may take in removing content. Although we do not monitor this content, we reserve the right to immediately take corrective action such as completely or partially removing content or suspending or terminating any and all Services without refund if you violate the Terms.</li>
                                </ol>
                            </li>
                        </ol>
                    </Container>
                </section>

                <SimpleFooter />
            </>
        );
    }
}

export default tos;
