import React from "react";
import {Badge, Card, CardImg, Col, Container, Row} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEthernet, faHdd, faMemory} from "@fortawesome/free-solid-svg-icons";

// reactstrap components

const Nodes = ( props ) => {

    return (
        <>
            <section className={`section ${props.forMinecraft ? '' : 'bg-secondary'}`}>
                <Container>
                    <Row className="row-grid align-items-center">
                        <Col md="6">
                            <Card className="bg-default shadow border-0 floating">
                                <CardImg
                                    alt="..."
                                    src={require('assets/img/theme/ryzen.png')}
                                    top
                                />
                                <blockquote className="card-blockquote">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="svg-bg"
                                        preserveAspectRatio="none"
                                        viewBox="0 0 583 95"
                                    >
                                        <polygon
                                            className="fill-default"
                                            points="0,52 583,95 0,95"
                                        />
                                        <polygon
                                            className="fill-default"
                                            opacity=".2"
                                            points="0,42 583,95 683,0 0,95"
                                        />
                                    </svg>
                                    <h4 className="display-3 font-weight-bold text-white">
                                        High Performance
                                    </h4>
                                    <p className="lead text-italic text-white">
                                        All our servers utilize the power of Ryzen 7 PRO
                                        which is able to boost up to 4.4Ghz, making your
                                        server lightning-fast even under the heaviest load.
                                    </p>
                                </blockquote>
                            </Card>
                        </Col>
                        <Col md="6">
                            <div className="pl-md-5">
                                <div className="icon icon-lg icon-shape icon-shape-warning shadow rounded-circle mb-5">
                                    <i className="ni ni-settings" />
                                </div>
                                <h3>Our nodes</h3>
                                <p className="lead">
                                    We don't compromise on performance! That's why we chose
                                    Ryzen 7 PRO 3700 @ 3.6Ghz. Below you can see our node
                                    specifications:
                                </p>

                                <div className="d-flex align-items-center mb-4 mt-2">
                                    <Badge className="badge-circle badge-success mr-3 text-white">
                                        <i className="fa fa-microchip"/>
                                    </Badge>
                                    <b className="mr-1">CPU</b> AMD Ryzen™ 7 PRO 3700
                                </div>
                                <div className="d-flex align-items-center mb-4">
                                    <Badge className="badge-circle badge-success mr-3 text-white">
                                        <FontAwesomeIcon icon={faHdd}/>
                                    </Badge>
                                    <b className="mr-1">Disk</b> M.2 NVMe Drives
                                </div>
                                <div className="d-flex align-items-center mb-4">
                                    <Badge className="badge-circle badge-success mr-3 text-white">
                                        <FontAwesomeIcon icon={faMemory}/>
                                    </Badge>
                                    <b className="mr-1">RAM</b> 64GB DDR4 Memory
                                </div>
                                <div className="d-flex align-items-center mb-4">
                                    <Badge className="badge-circle badge-success mr-3 text-white">
                                        <FontAwesomeIcon icon={faEthernet}/>
                                    </Badge>
                                    <b className="mr-1">Network</b> 10 Gbit/s Network
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default Nodes