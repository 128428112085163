import React from "react";
import {Col, Container, Row} from "reactstrap";

class Hero extends React.Component {

    render() {
        return (
            <>
                <section className="section section-lg pb-250" style={{ backgroundImage: `url(${require("assets/img/theme/background.jpg")})`, backgroundPosition: 'center', backgroundAttachment: 'fixed', backgroundSize: 'cover' }}>
                    <Container className="py-lg-md d-flex mt-5">
                        <div className="col px-0">
                            <Row className="align-items-center justify-content-center text-center" >
                                <Col lg="6">
                                    <h1 className="display-1 text-white mt-7">
                                        Minecraft Hosting
                                    </h1>
                                    <h2 className="display-4 font-weight-normal text-white">The power of the future is just a few clicks away from you!</h2>
                                </Col>
                            </Row>
                        </div>
                    </Container>

                    {/* SVG separator */}
                    <div className="separator separator-bottom separator-skew">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            preserveAspectRatio="none"
                            version="1.1"
                            viewBox="0 0 2560 100"
                            x="0"
                            y="0"
                        >
                            <polygon
                                className="fill-white"
                                points="2560 0 2560 100 0 100"
                            />
                        </svg>
                    </div>
                </section>
            </>
        )
    }
}

export default Hero