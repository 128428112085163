import React from "react";
import {Col, Container, Row} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarAlt, faFileAlt, faMugHot, faRedoAlt, faUserLock} from "@fortawesome/free-solid-svg-icons";

// reactstrap components

const Features = ( props ) => {
    return (
        <>
            <section className="section section-lg pt-0 mt-5">
                <Container className="mt-6">
                    <div className="col-md-8 mx-auto text-center mb-4">
                        <h3 className="display-3">Full-Featured Hosting System</h3>
                        <p className="lead">Arcadia Services provides what every other hosting does, <b>but better!</b></p>
                    </div>
                    <Row className="mt-5">
                        <Col className="col-md-4">
                            <div className="info">
                                <div className="icon icon-lg icon-shape icon-shape-primary shadow rounded-circle">
                                    <i className="ni ni-settings-gear-65"/>
                                </div>
                                <h6 className="info-title text-uppercase text-primary">Quality Dashboard</h6>
                                <p className="description opacity-8">We use Pterodactyl and Virtualizor for our Panels. They provide great amount of features, while being lightning-fast & having a bunch of features</p>
                                <a href="https://panel.thearcadia.xyz" className="text-primary">Login to your Dashboard
                                    <i className="ni ni-bold-right text-primary"/>
                                </a>
                            </div>
                        </Col>
                        <Col className="col-md-4">
                            <div className="info">
                                <div className="icon icon-lg icon-shape icon-shape-success shadow rounded-circle">
                                    <i className="ni ni-settings-gear-65"/>
                                </div>
                                <h6 className="info-title text-uppercase text-success">Fast & Secure Network</h6>
                                <p className="description opacity-8">With us, you'll never be afraid of possible DDoS attacks again. We provide lightning-fast DDoS Protection and Mitigation, up to <b>120MPPS</b></p>
                                <a href="https://panel.thearcadia.xyz" className="text-success">Learn more
                                    <i className="ni ni-bold-right text-success"/>
                                </a>
                            </div>
                        </Col>
                        <Col className="col-md-4">
                            <div className="info">
                                <div className="icon icon-lg icon-shape icon-shape-warning shadow rounded-circle">
                                    <i className="ni ni-settings-gear-65"/>
                                </div>
                                <h6 className="info-title text-uppercase text-warning">High Performance</h6>
                                <p className="description opacity-8">We believe that the most important thing about any hosting provider is the performance - That's why we use <b>Ryzen 7 3700</b> across all our lineups</p>
                                <a href="https://www.amd.com/en/products/cpu/amd-ryzen-7-pro-3700" className="text-warning">Learn more
                                    <i className="ni ni-bold-right text-warning"/>
                                </a>
                            </div>
                        </Col>
                    </Row>
                </Container>
                {props.showAdditional &&
                    <Container>
                        <Row className="row-grid align-items-center">

                            {/* Col #1 */}

                            <Col className="col-md-4 order-md-1">
                                <div className="pr-md-5">
                                    <ul className="list-unstyled mt-5">
                                        <li className="py-2">
                                            <div className="d-flex align-items-center">
                                                <div>
                                                    <div className="badge badge-circle badge-success mr-3">
                                                        <i className="fa fa-fire"/>
                                                    </div>
                                                </div>
                                                <div>
                                                    <h6 className="mb-0">Ultra-fast, CPU optimized servers</h6>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="py-2">
                                            <div className="d-flex align-items-center">
                                                <div>
                                                    <div className="badge badge-circle badge-success mr-3">
                                                        <i className="fa fa-dashcube"/>
                                                    </div>
                                                </div>
                                                <div>
                                                    <h6 className="mb-0">Intuitive GamePanel</h6>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="py-2">
                                            <div className="d-flex align-items-center">
                                                <div>
                                                    <div className="badge badge-circle badge-success mr-3">
                                                        <FontAwesomeIcon icon={faRedoAlt}/>
                                                    </div>
                                                </div>
                                                <div>
                                                    <h6 className="mb-0">Automatic server restarts if the server crashes.</h6>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </Col>

                            {/* Col #2 */}

                            <Col className="col-md-4 order-md-1">
                                <div className="pr-md-5">
                                    <ul className="list-unstyled mt-5">
                                        <li className="py-2">
                                            <div className="d-flex align-items-center">
                                                <div>
                                                    <div className="badge badge-circle badge-success mr-3">
                                                        <i className="fa fa-shield"/>
                                                    </div>
                                                </div>
                                                <div>
                                                    <h6 className="mb-0">Corero SmartWall</h6>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="py-2">
                                            <div className="d-flex align-items-center">
                                                <div>
                                                    <div className="badge badge-circle badge-success mr-3">
                                                        <i className="fa fa-info"/>
                                                    </div>
                                                </div>
                                                <div>
                                                    <h6 className="mb-0">Active support team</h6>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="py-2">
                                            <div className="d-flex align-items-center">
                                                <div>
                                                    <div className="badge badge-circle badge-success mr-3">
                                                        <i className="fa fa-dashboard"/>
                                                    </div>
                                                </div>
                                                <div>
                                                    <h6 className="mb-0">RAM usage enhancements powered by OpenJ9</h6>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="py-2">
                                            <div className="d-flex align-items-center">
                                                <div>
                                                    <div className="badge badge-circle badge-success mr-3">
                                                        <FontAwesomeIcon icon={faFileAlt}/>
                                                    </div>
                                                </div>
                                                <div>
                                                    <h6 className="mb-0">Full access to your files</h6>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </Col>

                            {/* Col #3 */}

                            <Col className="col-md-4 order-md-1">
                                <div className="pr-md-5">
                                    <ul className="list-unstyled mt-5">
                                        <li className="py-2">
                                            <div className="d-flex align-items-center">
                                                <div>
                                                    <div className="badge badge-circle badge-success mr-3">
                                                        <FontAwesomeIcon icon={faCalendarAlt}/>
                                                    </div>
                                                </div>
                                                <div>
                                                    <h6 className="mb-0">Easily able to schedule automated tasks.</h6>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="py-2">
                                            <div className="d-flex align-items-center">
                                                <div>
                                                    <div className="badge badge-circle badge-success mr-3">
                                                        <FontAwesomeIcon icon={faMugHot}/>
                                                    </div>
                                                </div>
                                                <div>
                                                    <h6 className="mb-0">Use any java version you want - just contact us</h6>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="py-2">
                                            <div className="d-flex align-items-center">
                                                <div>
                                                    <div className="badge badge-circle badge-success mr-3">
                                                        <FontAwesomeIcon icon={faUserLock}/>
                                                    </div>
                                                </div>
                                                <div>
                                                    <h6 className="mb-0">2FA Authentification</h6>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                        <Row className="row justify-content-center mt-4 text-center">
                            <Col className="col-lg-4 col-md-6 mt-4 mt-lg-0">
                                <div className="card">
                                    <div className="card-body py-4">
                                        <h6 className="text-success">Docker Isolation</h6>
                                        <p className="description mt-3 mb-0">All servers are completely isolated within Docker containers for security.</p>
                                    </div>
                                </div>
                            </Col>
                            <Col className="col-lg-4 col-md-6 mt-4 mt-lg-0">
                                <div className="card">
                                    <div className="card-body py-4">
                                        <h6 className="text-success">API Controls</h6>
                                        <p className="description mt-3 mb-0">We provide API access to control all servers, send commands and more.</p>
                                    </div>
                                </div>
                            </Col>
                            <Col className="col-lg-4 col-md-6 mt-4 mt-lg-0">
                                <div className="card">
                                    <div className="card-body py-4">
                                        <h6 className="text-success">MySQL Databases</h6>
                                        <p className="description mt-3 mb-0">We provide at least 2 databases for each and every server purchased.</p>
                                    </div>
                                </div>
                            </Col>

                        </Row>
                    </Container>
                }

            </section>
        </>
    )
}

export default Features