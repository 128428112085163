/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
// reactstrap components
import {
  Button,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDiscord, faGitlab} from "@fortawesome/free-brands-svg-icons";
import {Link} from "react-router-dom";

class SimpleFooter extends React.Component {
  render() {
    return (
      <>
        <footer className=" footer">
          <Container>
            <Row className=" row-grid align-items-center mb-5">
              <Col lg="6">
                <h3 className="text-primary font-weight-light mb-2">
                  {window.location.href.includes('deploysflow') ?
                      'DeploysFlow'
                      :
                      'Arcadia Services'
                  }
                </h3>
                <h4 className="mb-0 font-weight-light">
                  Let's get in touch on any of these platforms.
                </h4>
              </Col>
              <Col className="text-lg-right btn-wrapper" lg="6">
                <Button
                  className="btn-icon-only rounded-circle"
                  color="twitter"
                  href="https://twitter.com/ArcadiaService"
                  id="tooltip475038074"
                >
                  <span className="btn-inner--icon">
                    <i className="fa fa-twitter" />
                  </span>
                </Button>
                <UncontrolledTooltip delay={0} target="tooltip475038074">
                  Follow us
                </UncontrolledTooltip>
                <Button
                  className="btn-icon-only rounded-circle ml-1"
                  color="facebook"
                  href="https://www.facebook.com/thearcadia.xyz"
                  id="tooltip837440414"
                >
                  <span className="btn-inner--icon">
                    <i className="fa fa-facebook-square" />
                  </span>
                </Button>
                <UncontrolledTooltip delay={0} target="tooltip837440414">
                  Like us
                </UncontrolledTooltip>
                <Button
                  className="btn-icon-only rounded-circle ml-1"
                  color="primary"
                  href="https://discord.deploysflow.cloud"
                  id="tooltip829810202"
                >
                  <span className="btn-inner--icon">
                    <FontAwesomeIcon icon={faDiscord}/>
                  </span>
                </Button>
                <UncontrolledTooltip delay={0} target="tooltip829810202">
                  Join our Discord
                </UncontrolledTooltip>
                <Button
                  className="btn-icon-only rounded-circle ml-1"
                  color="dribbble"
                  href="https://gitlab.com/OpenSource02"
                  id="tooltip495507257"
                >
                  <span className="btn-inner--icon">
                    <FontAwesomeIcon icon={faGitlab}/>
                  </span>
                </Button>
                <UncontrolledTooltip delay={0} target="tooltip495507257">
                  Star on Gitlab
                </UncontrolledTooltip>
                <a style={{ display: 'block', marginTop: 20 }} href="https://status.thearcadia.xyz" id="freshstatus-badge-root" data-banner-style="compact">
                  <img src="https://public-api.freshstatus.io/v1/public/badge.svg/?badge=5759d9e4-acad-413e-a9bd-3596883898ec" alt={'Could not load status...'}/>
                </a>
              </Col>
            </Row>
            <hr />
            <Row className=" align-items-center justify-content-md-between">
              <Col md="6">
                <div className=" copyright">
                  © {new Date().getFullYear()}{" "}
                  <a
                      href=""
                  >
                    {window.location.href.includes('deploysflow') ?
                        'DeploysFlow'
                        :
                        'Arcadia Services'
                    }
                  </a>
                  .
                </div>
              </Col>
              <Col md="6">
                <Nav className=" nav-footer justify-content-end">
                  <NavItem>
                    <NavItem>
                      <NavLink
                          to="/privacypolicy" tag={Link}
                      >
                        Privacy Policy
                      </NavLink>
                    </NavItem>
                    <NavLink
                        to="/tos" tag={Link}
                    >
                      Terms of Service
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col>
            </Row>
          </Container>
        </footer>
      </>
    );
  }
}

export default SimpleFooter;
