import React from "react";
import {Col, Container, Row} from "reactstrap";

// reactstrap components

class ForWhat extends React.Component {
    render() {
        return (
            <>
                <section className="section section-lg">
                    <Container>
                        <Row className="row-grid align-items-center">
                            <Col className="order-md-2" md="6">
                                <img
                                    alt="..."
                                    className="img-fluid floating"
                                    src={require("assets/img/theme/server.png")}
                                />
                            </Col>
                            <Col className="order-md-1" md="6">
                                <h5>For Large Networks</h5>
                                <p>Are you having a huge network, but as your playercount increases, you're seeing more and more lag or network latency, which is affecting the gameplay experience of your players, but still paying an enormous amount of money, just to have 18 or less TPS? Well, than you've came to a right place! All of our servers are powered by 3rd gen Ryzen 7 PRO 3700, which is able to provide best in class performance at an affordable price.</p>
                                <br/><h5>For Beginners</h5>
                                <p>With our gorgeous game-panel & active support team, we guarantee that even if you're an absolute beginner, you'll be able to quickly advance and create the network of your dreams. No matter what you need - help with installing a plugin, help with connecting to SFTP, or maybe connecting your servers to BungeeCord network, we always have you covered - with Live chat or Support Tickets</p>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </>
        )
    }
}

export default ForWhat