import React from "react";
import {Button, Card, Col, Container, Row} from "reactstrap";

// reactstrap components

class Discord extends React.Component {
    render() {
        return (
            <>
                <section className="section section-lg pt-0">
                    <Container>
                        <Card className="bg-gradient-warning shadow-lg border-0">
                            <div className="p-5">
                                <Row className="align-items-center">
                                    <Col lg="8">
                                        <h3 className="text-white">
                                            Join our Discord
                                        </h3>
                                        <p className="lead text-white mt-3">
                                            If you have any questions or concerns, we're now available on Discord too! Connect with us now!
                                        </p>
                                    </Col>
                                    <Col className="ml-lg-auto" lg="3">
                                        <Button
                                            block
                                            className="btn-white"
                                            color="default"
                                            href="https://discord.deploysflow.cloud"
                                            size="lg"
                                        >
                                            Join our Discord
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                        </Card>
                    </Container>
                </section>
            </>
        )
    }
}

export default Discord