/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React from "react";
import SimpleFooter from "../../components/Footers/SimpleFooter";
import NavBarUntransparent from "../../components/Navbars/NavBarUntransparent";
import Hero from "./Sections/Minecraft/Hero";
import ForWhat from "./Sections/Minecraft/ForWhat";
import Panel from "../IndexSections/Panel";
import Discord from "../IndexSections/Discord";
import Features from "../IndexSections/Features";
import LoadingBar from "react-top-loading-bar";
import Prices from "./Sections/Minecraft/Prices";
import Nodes from "../IndexSections/Nodes";
// nodejs library that concatenates classes

// reactstrap components

// core components

// index page sections

class Minecraft extends React.Component {

    componentDidMount() {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        this.complete()
    }

    state = {
        loadingBarProgress: 10
    };

    complete = () => {
        this.setState({ loadingBarProgress: 100 });
    };

    onLoaderFinished = () => {
        this.setState({ loadingBarProgress: 0 });
    };

    render() {
        return (
            <>
                <LoadingBar
                    progress={this.state.loadingBarProgress}
                    height={3}
                    color="blue"
                    onLoaderFinished={() => this.onLoaderFinished()}
                />
                <NavBarUntransparent/>
                <Hero/>
                <ForWhat/>
                <Prices/>
                <Nodes forMinecraft/>
                <Panel/>
                <Features showAdditional/>
                <Discord/>
                <SimpleFooter/>
            </>
        );
    }
}

export default Minecraft;
